@import "bootstrap/scss/bootstrap";
@import url(../src/font.scss);

$secondary: rgb(58, 58, 60);
$primary: rgb(72, 160, 222);

.catamaran-regular {
  font-family: Catamaran-regular;
}

.catamaran-semiBold {
  font-family: Catamaran-semiBold;
}

.catamaran-medium {
  font-family: Catamaran-medium;
}

.catamaran-light {
  font-family: Catamaran-extraLight;
}

.background {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 20%, transparent),
    url("./assets/image.jpg");
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.menu-item {
  cursor: pointer;
  transition: all 0.5s ease;
  color: white;
  // font-weight: 600;
  font-family: Catamaran-semiBold;
  font-size: 1 rem;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in;
  transition: 0.6s;
}

.menu-item:hover,
.active {
  color: rgb(72, 160, 222);
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-border: 2px;
  background: rgb(7, 7, 7);
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
