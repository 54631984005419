@font-face {
    font-family: Catamaran-semiBold;
    src: url(../src/assets/fonts/Catamaran-SemiBold.ttf); 
}

@font-face {
    font-family: Catamaran-regular;
    src: url(../src/assets/fonts/Catamaran-Regular.ttf); 
}

@font-face {
    font-family: Catamaran-medium;
    src: url(../src/assets/fonts/Catamaran-Medium.ttf); 
}

@font-face {
    font-family: Catamaran-extraLight;
    src: url(../src/assets/fonts/Catamaran-ExtraLight.ttf); 
}

